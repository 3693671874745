<template>
  <div>
    <v-progress-linear
      indeterminate
      color="blue darken-2"
      :active="show"
    ></v-progress-linear>

 <v-container>
  <v-row align="center" justify="center" >
          <v-col cols="12" sm="8">
            <v-card class="elevation-6 mt-10"  >
             <v-row >
                    <v-col cols="12" md="6" class="blue rounded-br-xl">
                     <div style="  text-align: center; padding: 180px 0;">
                      <v-card-text class="white--text" >
                        <h2 class="text-center ">No recuerdas tu contraseña?</h2>
                        <h5
                          class="text-center"
                        >No te preocupes, nosotros te ayudamos a recuperarla!!! <br>
                        Escribe tu email, te enviaremos un correo con tu contraseña actual <br>
                        no olvides escribir el email con el que te registraste.</h5>
                      </v-card-text>                     
                      </div>
                    </v-col>

                    <v-col cols="12" md="6">
                      <v-card-text class="mt-12">
                        <h2
                          class="text-center"
                        >Recuperar contraseña</h2>
                        <h6
                          class="text-center  grey--text "
                        >Escribe tu email</h6>
                        <v-row align="center" justify="center">
                          <v-col cols="12" sm="10">
                          <v-text-field
                          v-model="form.email"
                          :rules="rules.email"
                          label="Email"
                          required
                           outlined
                            dense
                          class="input-group--focused"
                          append-icon="mail"
                        ></v-text-field>
                                                                  
                          <v-btn
                           color="blue"  
                            block tile  
                            :disabled="!formIsValid"
                            @click="submit()"
                             class="white--text"                           
                          >
                            Enviar
                          </v-btn>
                                                
                          </v-col>
                        </v-row>  
                      </v-card-text>
                    </v-col>
                  </v-row>
            </v-card>
          </v-col>
      </v-row>
  <div v-if="error" class="alert alert-danger">{{ error }}</div>
 </v-container>
    <!--<v-row justify="center" class="my-10">
      <v-card elevation="24" width="400">
        <v-card-title>
          <span class="headline">Recuperar Contraseña</span>
        </v-card-title>
        <v-form ref="form" @submit.prevent="submit">
          <v-card-text>
            <v-container fluid>
              <v-text-field
                v-model="form.email"
                :rules="rules.email"
                label="Email"
                required
                outlined
                dense
                placeholder="Introduce el email registrado"
                class="input-group--focused"
              ></v-text-field>
            </v-container>
          </v-card-text>

           <v-divider class="mt-12"></v-divider> 
          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn
              :disabled="!formIsValid
              color="primary"
              type="submit"
              elevation="24"
              block
            >
              Enviar
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
      <div v-if="error" class="alert alert-danger">{{ error }}</div>
    </v-row>-->

    <v-snackbar v-model="snackbar">
      <span>{{ messageSnackbar }}</span>
    </v-snackbar>
  </div>
</template>

<script>
import apiServices from "../Services/apiServices";

export default {
  name:"PasswordRecovery",
  data() {
    return {
      form: {
        email: "",
      },
      error: null,
      rules: {
        email: [(val) => (val || "").length > 0 || "Email requerido."],
      },
      snackbar: false,
      messageSnackbar: "",
      show: false,     
       show3:false,
    };
  },
  methods: {
    submit() {
      this.show = true;
      apiServices.PasswordRecoveryService.SendEmailPasswordRecovery(this.form)
        .then((resp) => {
         // alert(resp.data);
          this.$fire({
                position: 'top-end',
                title: resp.data,
                text: "Verifique su cuenta de correo.",
                type: "info",
                timer: 3000,
                toast:true,
                showConfirmButton: false,
              }).then(() => {
                //console.log(r.value);
              });  
          this.$router.replace({ name: "Login" });
          this.show = false;
        })
        .catch((error) => {
          this.show = false;
          if (error.response) {
            // get response with a status code not in range 2xx
            alert(error.response.data);
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            // no response
            console.log(error.request);
            // instance of XMLHttpRequest in the browser
            // instance ofhttp.ClientRequest in node.js
          } else {
            // Something wrong in setting up the request
            console.log("Error", error.message);
          }
          console.log("Error.config: " + error.config);
        });
      /* firebase
        .auth()
        .sendPasswordResetEmail(this.form.email)
        .then(() => {
          window.setTimeout(() => {
            this.sending = true;
            this.snackbar = true;
            this.messageSnackbar = "Se ha enviado un email a su correo..";
            this.$router.replace({ name: "Home", params: { uid: undefined } });
          }, 1500);
        })
        .catch((err) => {
          this.error = err.message;
        });*/
    },
  },
  computed: {
    formIsValid() {
      return this.form.email;
    },
  },
};
</script>
