class LoginService {
    axios
    baseUrl

    constructor(axios, apiUrl) {
        this.axios = axios
        this.baseUrl = `${apiUrl}token`
    }
  
    Authentication(userLogin) {
        let self = this;
        return self.axios.post(`${self.baseUrl}/Authentication`, userLogin);
    }

  
}

export default LoginService