class PasswordRecoveryService {
    axios
    baseUrl

    constructor(axios, apiUrl) {
        this.axios = axios
        this.baseUrl = `${apiUrl}sendemail`
    }

    SendEmailPasswordRecovery(email) {
        let self = this;
        return self.axios.post(`${self.baseUrl}/SendEmailPasswordRecovery`, email);
    }
  
}

export default PasswordRecoveryService