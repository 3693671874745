
import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)
//https://blog.sqreen.com/authentication-best-practices-vue/
//https://austingil.com/prevent-browser-refresh-url-changes-route-navigation-vue/
export default new Vuex.Store({
  
    state: {       
        user: {
            token: '',            
            loggedIn: false,   
            loggedInAll:false,      
            data: null,           
        }
      },
      getters: {
        user(state){
          //var userdata = JSON.parse( localStorage.getItem('userdata') )
         // if(userdata)
        //  state.user = userdata
          return state.user
        }
      },
      mutations: {
       // SET_TOKEN(state, value) {
       //     state.user = value;
       //   },
       // SET_LOGGED_IN(state, value) {
       //   state.user.loggedIn = value;
       // },
        SET_USER(state, user) {
          state.user = user;
         
        },       
      },
      actions: {
        fetchUser({ commit }, user) {              
          //commit("SET_LOGGED_IN", user !== null);

          let usr =  {
            token: null,
            loggedIn: false,
            loggedInAll:false,
            data: {
              displayName:null,
              email:null,
              uid: null,
              role: null,           
            },           
          };

          if (user) {

            usr.token = user.token;
            usr.loggedIn= user.token.length ? true : false;
            usr.loggedInAll = user.loggedInAll;
            usr.data.displayName=user.data.displayName;
            usr.data.email=user.data.email;
            usr.data.uid= user.data.uid;
            usr.data.role= user.data.role;
           
            commit("SET_USER", usr);
            //localStorage.setItem('userdata', JSON.stringify(usr))
           
            /*commit("SET_USER", {
              displayName: user.displayName,
              email: user.email,
              uid: user.uid,
              role: user.role,
              phone: user.phone,
              specialty: user.specialty,
            });*/
          } else {
            commit("SET_USER", usr);
           // localStorage.removeItem('userdata')
          }
         
        },       
      }
})