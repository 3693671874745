<template>
  <div class="text-center">
    <v-overlay opacity=".8" :value="loadingShow">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
      <v-card-title>{{ loadingTitle }}</v-card-title>
    </v-overlay>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  props: {
    loadingShow: null,
    loadingTitle: null,
  },
};
</script>
