<template>
  <div>
    <v-row justify="center" class="my-10">
      <v-card elevation="24" width="400">
        <v-card-title>
          <span class="headline">Registro</span>
        </v-card-title>
        <v-form ref="form" @submit.prevent="submit">
          <v-card-text>
            <v-container fluid>
              <v-text-field
                v-model="form.userName"
                :rules="rules.userName"
                label="Nombre Completo"
                required
                class="input-group--focused"
                append-icon="person"
              ></v-text-field>
              <v-text-field
                v-model="form.email"
                :rules="rules.email"
                label="Email"
                required
                class="input-group--focused"
                append-icon="mail"
              ></v-text-field>
              <v-text-field
                :append-icon="show3 ? 'visibility' : 'visibility_off'"
                v-model="form.password"
                :rules="rules.password"
                label="Password"
                required
                :type="show3 ? 'text' : 'password'"
                class="input-group--focused"
                @click:append="show3 = !show3"
              ></v-text-field>
              <v-text-field
                v-model="form.phone"
                :rules="rules.phone"
                color="blue darken-2"
                label="Teléfono"
                required
                append-icon="phone"
                @input="enforcePhoneFormat()"
                maxlength="16"
                placeholder="(123) 456-7890"
              ></v-text-field>
              <v-text-field
                v-model="form.specialty"
                :rules="rules.specialty"
                label="Especialidad"
                required
                class="input-group--focused"
                append-icon="contact_page"
              ></v-text-field>
            </v-container>
          </v-card-text>

          <!-- <v-divider class="mt-12"></v-divider> -->
          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn
              :disabled="!formIsValid"
              color="primary"
              type="submit"
              elevation="24"
              block
            >
              Registrar
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
      <div v-if="error" class="alert alert-danger">{{ error }}</div>
    </v-row>
  </div>
</template>

<script>
import apiServices from "../Services/apiServices";

export default {
  data() {
    const defaultForm = Object.freeze({
      userName: "",
      password: "",
      email: "",
      phone: "",
      specialty: "",
    });
    return {
      form: Object.assign({}, defaultForm),
      error: null,
      show3: false,
      rules: {
        userName: [(val) => (val || "").length > 0 || "Nombre requerido."],
        password: [(val) => (val || "").length > 0 || "Password requerido."],
        email: [
          (val) => (val || "").length > 0 || "Email requerido.",
          (value) => {
            const pattern =
              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return pattern.test(value) || "Email invalido.";
          },
        ],
        phone: [(val) => (val || "").length > 0 || "Teléfono requerido."],
        specialty: [
          (val) => (val || "").length > 0 || "Especialidad requerida.",
        ],
      },
    };
  },
  methods: {
    resetForm() {
      this.form = Object.assign({}, this.defaultForm);
      this.$refs.form.reset();
    },
    enforcePhoneFormat() {
      let x = this.form.phone
        .replace(/\D/g, "")
        .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);

      this.form.phone = !x[2]
        ? x[1]
        : "(" + x[1] + ") " + x[2] + (x[3] ? "-" + x[3] : "");
    },
    submit() {
      let item = {
        id: "",
        userName: this.form.userName,
        password: this.form.password,
        email: this.form.email,
        phone: this.form.phone,
        specialty: this.form.specialty,
      };

      apiServices.userService
        .InsertUser(item)
        .then(() => {
          //this.resetForm();
          //alert("Usuario registrado correctamente!");
           this.$fire({
                position: 'top-end',
                title: "Usuario registrado correctamente!",
                //text: "ha sido registrado en la aplicación.",
                type: "info",
                timer: 3000,
                toast:true,
                showConfirmButton: false,
              }).then(() => {
                //console.log(r.value);
              });  
          this.$router.push({
            name: "Login",
          });
        })
        .catch((error) => {
          if (error.response) {
            // get response with a status code not in range 2xx
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            // no response
            console.log(error.request);
            // instance of XMLHttpRequest in the browser
            // instance ofhttp.ClientRequest in node.js
          } else {
            // Something wrong in setting up the request
            console.log("Error", error.message);
          }
          console.log("Error.config: " + error.config);
        });

      /* firebase
        .auth()
        .createUserWithEmailAndPassword(this.form.email, this.form.password)
        .then((data) => {
          data.user
            .updateProfile({
              displayName: this.form.name,
            })
            .then(() => {
              alert("Usuario registrado correctamente!");
              this.form = {
                name: "",
                email: "",
                password: "",
              };
              this.$router.replace({
                name: "Home",
                params: { uid: data.user.uid },
              });
            });
        })
        .catch((err) => {
          this.error = err.message;
        });*/
    },
  },
  computed: {
    formIsValid() {
      return (
        this.form.userName &&
        this.form.email &&
        this.form.password &&
        this.form.phone &&
        this.form.specialty
      );
    },
  },
};
</script>
